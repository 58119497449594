@import "~mapbox-gl/dist/mapbox-gl.css";
@import "./app/views/map-management/map/generalmap_styles_variables";
// @import "@angular/material/prebuilt-themes/deeppurple-amber.css";
// @import "./app/utils/mapboxgl-control-minimap/mapboxgl-ctrl-minimap.css";
/* You can add global styles to this file, and also import other style files */

*{
  box-sizing: border-box;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// Cluster Icons
.custom-clustericon {
  background: var(--cluster-color);
  color: #fff;
  border-radius: 100%;
  font-weight: bold;
  font-size: 15px;
  display: flex;
  align-items: center;

  &::before, &::after{
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;

    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background: var(--cluster-color);
    opacity: 0.2;
    border-radius: 100%;
  }
  &::before {
    padding: 7px;
  }

  &::after {
    padding: 14px;
  }

  &-1{
    --cluster-color: #00a2d3;
  }
  &-2{
    --cluster-color: #ff9b00;
  }
  &-3{
    --cluster-color: #ff6969;
  }
}

// loader styles
.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba($color_primary, 85%);

}

